import { axiosChartInstance } from "config";
import { OrderGroup } from "enums";
import { useAxiosQuery } from "hooks";

type UseOrderChartParams = {
  encounterId: string;
  chartCode: string;
  orderType: OrderGroup;
};

type UseOrderChartResponseItem = {
  orderId: string;
  orderNumber: number;
  answers: {
    qCode: string;
    value: string;
  }[];
};

function useOrderChart(params: UseOrderChartParams) {
  const { encounterId, orderType } = params;
  return useAxiosQuery(["orderChart", params], () =>
    axiosChartInstance.get<UseOrderChartResponseItem[]>(
      `v1/order/${encounterId}`,
      { params: { orderType } }
    )
  );
}

export type { UseOrderChartParams, UseOrderChartResponseItem };
export { useOrderChart };
